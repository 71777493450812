<template>
  <v-col cols="auto" v-if="isConfirmed" class="d-flex">
    <v-icon color="success" left small> fas fa-check </v-icon>

    <BaseText type="p" class="mb-0 ml-1">
      {{ $t("SEMINAR_SPEAKER.CONTRACT_DETAILS.STATUSES.CONFIRMED") }}
    </BaseText>
  </v-col>

  <v-col cols="auto" v-else-if="isCancelled" class="d-flex">
    <v-icon color="error" left small> fas fa-times </v-icon>

    <BaseText type="p" class="mb-0 ml-1">
      {{ $t("SEMINAR_SPEAKER.CONTRACT_DETAILS.STATUSES.CANCELLED") }}
    </BaseText>
  </v-col>
</template>

<script>
import contractStatusActions from "@data/contractStatusActions";

export default {
  props: {
    contractActions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isConfirmed() {
      return (
        this.contractActions?.length &&
        this.contractActions.some((contractAction) => {
          return contractAction.action === contractStatusActions.CONFIRM;
        }) &&
        !this.isCancelled
      );
    },

    isCancelled() {
      return (
        this.contractActions?.length &&
        this.contractActions.some((contractAction) => {
          return contractAction.action === contractStatusActions.CANCEL;
        })
      );
    },
  },
};
</script>
