<template>
  <div>
    <v-row v-if="shouldShowIndicator" no-gutters class="px-6 pb-6">
      <ContractActionIndicator :contractActions="contractActions" />
    </v-row>
  </div>
</template>

<script>
import speakerContractStatusGroups from "../../constants/speakerContractStatusGroups";
import ContractActionIndicator from "@components/ContractActionIndicator/ContractActionIndicator.vue";

export default {
  name: "NeedForActionIndicator",
  components: { ContractActionIndicator },

  props: {
    group: {
      type: Object,
      required: true,
      default: () => ({
        key: null,
        name: null,
        color: null,
      }),
    },
    seminarId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      contractActions: null,
    };
  },

  computed: {
    shouldShowIndicator() {
      return this.groupMatches && !this.isLoading && this.contractActions?.length;
    },

    groupMatches() {
      return this.group === speakerContractStatusGroups.NEED_FOR_ACTION;
    },
  },

  methods: {
    async getSpeakerContractAction() {
      this.isLoading = true;

      try {
        let response = await this.$axios.seminarManagement.listContracts(this.seminarId);
        const contract = response.data.data[0] || null;

        if (!contract) {
          this.isLoading = false;

          return;
        }

        response = await this.$axios.seminarManagement.listContractActions(contract.id);
        this.contractActions = response.data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    if (this.groupMatches) {
      this.getSpeakerContractAction();
    }
  },
};
</script>
