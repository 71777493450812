import i18n from "../i18n";

export default {
  OPEN: {
    key: "open",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.OPEN"),
    colors: {
      text: "warningText",
      background: "warningBackground",
    },
  },
  NEED_FOR_ACTION: {
    key: "needForAction",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.NEED_FOR_ACTION"),
    colors: {
      text: "primary",
      background: "primary200",
    },
  },
  ACCEPTED: {
    key: "accepted",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.ACCEPTED"),
    colors: {
      text: "success",
      background: "successBackground",
    },
  },
  CANCELLED: {
    key: "cancelled",
    name: i18n.t("SEMINAR_SPEAKER.CONTRACTS.STATUSES.CANCELLED"),
    colors: {
      text: "critical",
      background: "criticalBackground",
    },
  },
};
